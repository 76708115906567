import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw'


const AppDetails = ({data: {strapiAppDetails}}) => (
  <Layout>
   
    <SEO title="PETNECK2 App Details" />

    <div class="content">
      <div class="title">
          
      <p><ReactMarkdown rehypePlugins={[rehypeRaw]}>{strapiAppDetails.Title}</ReactMarkdown></p>
      </div>
      <div class="col1">
        <p><ReactMarkdown rehypePlugins={[rehypeRaw]}>{strapiAppDetails.Column1}</ReactMarkdown></p>
      </div>
      <div class="col2">
        <p><ReactMarkdown rehypePlugins={[rehypeRaw]}>{strapiAppDetails.Column2}</ReactMarkdown></p>
      </div>
      <div class="col3">
        <p><ReactMarkdown rehypePlugins={[rehypeRaw]}>{strapiAppDetails.Bottom}</ReactMarkdown></p>
      </div>
    </div>

  </Layout>
)

export const appDetailsQuery = graphql`
  query AppDetailsQuery {
    strapiAppDetails {
            Title
            Column1
            Column2
            created_at
    }
  }
`

export default  AppDetails